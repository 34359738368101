import { Cell, StackName } from '../../../../store/types/configResponse';
import BhPost10 from '../../components/BhPost10';
import BhScreenDb from '../../components/BhScreenDb/BhScreenDb';
import BhTerminalPure from '../../components/BhTerminalPure';
import Loundry2 from '../../components/Loundry2';
import Magyar5 from '../../components/Magyar5/Magyar5';
import Magyar9 from '../../components/Magyar9/Magyar9';
import X5Cells10 from '../../components/X5Cells10';
import X5Cells18 from '../../components/X5Cells18';
import X5Terminal9 from '../../components/X5Terminal9';
import { MOCKED_CELLS } from '../../mocks/cells';
import BhFresh from '../BhFresh/BhFresh';
import BhVending from '../BhVending/BhVending';
import UltraCommon from '../UltraCommon';

interface ICombineStacks {
  stackId: number;
  name: string;
  cell: number;
  cells: Cell[];
  isActiveStack: boolean;
}

export default function CombineStacks({
  stackId,
  name,
  cell,
  cells,
  isActiveStack,
}: ICombineStacks): JSX.Element {
  switch (name) {
    case StackName.ultra17:
    case StackName.ultra18:
    case StackName.postuaUltraQpro:
    case StackName.postuaUltra18:
      return (
        <UltraCommon
          key={name + stackId}
          isActiveStack={isActiveStack}
          activeCellId={cell}
          cells={cells}
        />
      );

    case StackName.bhPost10:
    case StackName.laundryPost10:
      return (
        <BhPost10
          key={name + stackId}
          isActiveStack={isActiveStack}
          activeCellId={cell}
          cells={cells}
        />
      );

    case StackName.bhTerminalPure:
    case StackName.laundryTerminalPure:
      return (
        <BhTerminalPure
          key={name + stackId}
          isActiveStack={isActiveStack}
          activeCellId={cell}
          cells={cells}
        />
      );

    case StackName.bhFresh:
      return (
        <BhFresh
          key={name + stackId}
          isActiveStack={isActiveStack}
          activeCellId={cell}
          cells={cells}
        />
      );

    case StackName.bhVending:
      return (
        <BhVending
          key={name + stackId}
          isActiveStack={isActiveStack}
          activeCellId={cell}
          cells={cells}
        />
      );

    case StackName.loundry2:
    case StackName.bhLaundry:
      return (
        <Loundry2
          key={name + stackId}
          isActiveStack={isActiveStack}
          activeCellId={cell}
          cells={cells}
        />
      );

    case StackName.bhScreenDb:
      return (
        <BhScreenDb
          key={name + stackId}
          isActiveStack={isActiveStack}
          activeCellId={cell}
          cells={cells}
        />
      );

    case StackName.magyarTerminal5:
      return (
        <Magyar5
          key={name + stackId}
          isActiveStack={isActiveStack}
          activeCellId={cell}
          cells={cells}
        />
      );

    case StackName.magyarBase9:
      return (
        <Magyar9
          key={name + stackId}
          isActiveStack={isActiveStack}
          activeCellId={cell}
          cells={cells}
        />
      );

    case StackName.x5Cells10:
      return (
        <X5Cells10
          key={name + stackId}
          isActiveStack={isActiveStack}
          activeCellId={cell}
          cells={cells}
        />
      );

    case StackName.x5Terminal9:
      return (
        <X5Terminal9
          key={name + stackId}
          isActiveStack={isActiveStack}
          activeCellId={cell}
          cells={cells}
        />
      );

    case StackName.x5Cells18:
      return (
        <X5Cells18
          key={name + stackId}
          isActiveStack={isActiveStack}
          activeCellId={cell}
          cells={cells}
        />
      );

    default:
      return (
        <UltraCommon
          key={name + stackId}
          isActiveStack={false}
          cells={MOCKED_CELLS}
        />
      );
  }
}
