import styled, { css } from 'styled-components';

import { CellPropsCommon } from '../../types';

import { stackCellCommon, stackCellsContainerCommon } from '../../styled';

function applyScreen({ isScreen }: CellPropsCommon) {
  return (
    isScreen &&
    css`
      &::before {
        content: '';
        position: absolute;
        width: 92%;
        height: 82%;
        background: linear-gradient(
          179.35deg,
          rgba(161, 193, 203, 0.4) 0.56%,
          rgba(236, 236, 236, 0.4) 99.44%
        );
      }
    `
  );
}

export const CellsContainer = styled.div`
  ${stackCellsContainerCommon};
  display: flex;
  flex-direction: column;
  gap: 1px;
`;

export const Cell = styled.div`
  ${stackCellCommon};
  ${applyScreen};
  &:nth-child(1) {
    height: 22%;
  }
  &:nth-child(2),
  &:nth-child(3) {
    height: 27%;
  }

  &:nth-child(4) {
    height: 24%;
  }
`;
