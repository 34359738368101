import styled, { css } from 'styled-components';

import { CellPropsCommon } from '../../types';

import { stackCellCommon, stackCellsContainerCommon } from '../../styled';

function applyScreen({ isScreen }: CellPropsCommon) {
  return (
    isScreen &&
    css`
      &::before {
        content: '';
        position: absolute;
        width: 92%;
        height: 82%;
        background: linear-gradient(
          179.35deg,
          rgba(161, 193, 203, 0.4) 0.56%,
          rgba(236, 236, 236, 0.4) 99.44%
        );
      }
    `
  );
}

export const CellsContainer = styled.div`
  ${stackCellsContainerCommon};
  display: flex;
  flex-direction: column;
  gap: 1px;
`;

export const Cell = styled.div`
  ${stackCellCommon};

  ${applyScreen};
  &:nth-child(1) {
    height: 20%;
  }
  &:nth-child(2),
  &:nth-child(5),
  &:nth-child(6) {
    height: 7%;
  }
  &:nth-child(3),
  &:nth-child(4) {
    height: 12%;
  }
  &:nth-child(7),
  &:nth-child(8) {
    height: 9%;
  }
  &:nth-child(9) {
    height: 17%;
  }
`;
