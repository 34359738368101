import { StackProps } from '../../types';

import { SizeContainer, StackBody, StackWrapper } from '../../styled';
import { Cell, CellsContainer } from './styled';

export default function X5Cells10({
  cells,
  isActiveStack,
  activeCellId,
}: StackProps): JSX.Element {
  return (
    <SizeContainer role="presentation" aria-label="Postamat">
      <StackWrapper>
        <StackBody>
          <CellsContainer>
            {cells.map(({ cell, row, screen }) => {
              const isActiveCell = isActiveStack && activeCellId === cell;
              const ariaLabel = isActiveCell
                ? 'Cell with your order'
                : 'Postamat cell';

              return (
                <Cell
                  key={row + cell}
                  isActiveCell={isActiveCell}
                  aria-label={ariaLabel}
                  isScreen={screen}
                />
              );
            })}
          </CellsContainer>
        </StackBody>
      </StackWrapper>
    </SizeContainer>
  );
}
