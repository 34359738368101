import { useSelector } from 'react-redux';

import {
  selectConfig,
  selectOpenedCellData,
} from '../../store/slices/configSlice';
import Loader from '../Loader';
import CombineStacks from './components/CombineStacks/CombineStacks';
import UltraCommon from './components/UltraCommon';
import { useGetDeviceConfig } from './hooks/useGetDeviceConfig';
import { MOCKED_CELLS } from './mocks/cells';

import {
  PostamatContainer,
  PostamatHead,
  PostamatWrapper,
  StacksContainer,
} from './styled';

export default function Postamat(): JSX.Element {
  const deviceConfig = useSelector(selectConfig);
  const configStatus = useGetDeviceConfig();

  const openedCellData = useSelector(selectOpenedCellData);

  const isShowHighlightCell = !!deviceConfig && !!openedCellData;

  const shownData = isShowHighlightCell ? (
    deviceConfig.stacks.map(({ cells, stack: stackId, name }) => {
      const { cell, stack } = openedCellData;
      const isActiveStack = stack === stackId;

      const isSkipTemplate = cells.length <= 1;

      if (isSkipTemplate) return;

      return (
        <CombineStacks
          stackId={stackId}
          name={name}
          cell={cell}
          cells={cells}
          isActiveStack={isActiveStack}
        />
      );
    })
  ) : (
    <UltraCommon isActiveStack={false} cells={MOCKED_CELLS} />
  );

  return configStatus === 'loading' ? (
    <Loader />
  ) : (
    <PostamatContainer>
      <PostamatWrapper>
        <PostamatHead />
        <StacksContainer>{shownData}</StacksContainer>
      </PostamatWrapper>
    </PostamatContainer>
  );
}
