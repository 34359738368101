import { Extra } from '../../api/types';
import { Flow } from '../../types/appLogic';
import { Status } from './slicesTypes';

export enum StackName {
  ultra17 = 'ultra-17',
  ultra18 = 'ultra-18',
  postuaUltraQpro = 'postua-ultra-qpro',
  postuaUltra18 = 'postua-ultra-18',

  bhPost10 = 'bh-post-10',
  laundryPost10 = 'laundry-post-10',

  bhTerminalPure = 'bh-terminal-pure',
  bhFresh = 'bh-fresh',
  bhVending = 'bh-vending',
  laundryTerminalPure = 'laundry-terminal-pure',

  loundry2 = 'loundry2',
  bhLaundry = 'bh_laundry',

  bhScreenDb = 'bh-screen-db',
  bhScreenDbQpro = 'bh-screen-db-qpro',

  magyarTerminal5 = 'magyar-terminal-5',
  magyarBase9 = 'magyar-base-9',

  x5Terminal9 = 'terminal-x5-9',
  x5Cells10 = 'x5-10',
  x5Cells18 = 'x5-18',
}

export interface Cell {
  cell: number;
  width: number;
  height: number;
  depth: number;
  screen: boolean;
  row: number;
}

export interface Stack {
  name: StackName;
  stack: number;
  width: number;
  height: number;
  depth: number;
  cells: Cell[];
}

export interface ConfigResponse {
  device: string;
  stacks: Stack[];
}

export interface OpenCellArg {
  deviceUid: string;
  orderUid: string;
  flow: Flow;
}

export interface InitialState {
  openedCellData: Extra | null;
  configStatus: Status;
  openingStatus: Status;
  config: null | ConfigResponse;
}
