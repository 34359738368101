import styled from 'styled-components';

import { stackCellCommon, stackCellsContainerCommon } from '../../styled';

export const Cell = styled.div`
  width: 100%;
  height: 100%;
  ${stackCellCommon};

  &:nth-last-child(2) {
    grid-column: 1 / 3;
    grid-template: 1;
  }
  &:nth-last-child(1) {
    grid-column: 1 / 3;
    grid-template: 10;
  }
`;

export const CellsContainer = styled.div`
  ${stackCellsContainerCommon};
  display: grid;
  gap: 1px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 11% repeat(3, 9%) repeat(4, 6.5%) 11% auto;
`;
